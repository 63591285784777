import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import About from "../containers/company/about";

export default () => (
    <Layout>
        <SEO title="About" />
        <About />
    </Layout>
);
